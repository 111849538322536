/* eslint-disable @typescript-eslint/no-var-requires, no-use-before-define */
const React = require('react')
const RootElementProvider =
  require('@talentinc/gatsby-theme-ecom/components/Providers/RootElement').default
const ZipJobTheme = require('./src/styles').ZipJobTheme

/**
 * @type {import('gatsby').GatsbyBrowser['wrapRootElement']}
 */
exports.wrapRootElement = ({ element }) => {
  return (
    <RootElementProvider theme={ZipJobTheme}>{element}</RootElementProvider>
  )
}
